var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null ? _vm.$t("cadastrar-grupo") : _vm.$t("editar-grupo"),
      },
    },
    [
      _c(
        "vs-tabs",
        { attrs: { color: _vm.colorx } },
        [
          _c("vs-tab", { attrs: { label: _vm.$t("display_name") } }, [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: _vm.$t("name") },
                    model: {
                      value: _vm.display_name,
                      callback: function ($$v) {
                        _vm.display_name = $$v
                      },
                      expression: "display_name",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("display_name"),
                          expression: "errors.has('display_name')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("display_name")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "email", label: _vm.$t("description") },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("description"),
                          expression: "errors.has('description')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("description")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("fields.institution")))]),
                  _c("vs-divider", { staticClass: "mt-0" }),
                  _c("select-suggestion", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "general_system_admin",
                        expression: "'general_system_admin'",
                      },
                    ],
                    attrs: {
                      column: "id,name",
                      model: "Institution",
                      appendClearOption: true,
                      placeholderText: "Digite o nome ou id da instituição",
                    },
                    model: {
                      value: _vm.institution,
                      callback: function ($$v) {
                        _vm.institution = $$v
                      },
                      expression: "institution",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary", type: "border" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right mr-2",
                      on: { click: _vm.create },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.save")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.id !== null
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("functionality.titles") } },
                [
                  _c(
                    "vs-collapse",
                    { attrs: { accordion: "", type: "border" } },
                    _vm._l(_vm.functionalityList, function (func) {
                      return _c(
                        "vs-collapse-item",
                        { key: func.id },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_vm._v(" " + _vm._s(func.name) + " ")]
                          ),
                          _c(
                            "vs-table",
                            {
                              key: _vm.id,
                              attrs: { "no-data": " ", data: func.permissions },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ data }) {
                                      return _vm._l(data, function (tr, i) {
                                        return _c(
                                          "vs-tr",
                                          { key: i },
                                          [
                                            _c(
                                              "vs-td",
                                              { attrs: { data: data[i].id } },
                                              [
                                                _c("vs-checkbox", {
                                                  attrs: {
                                                    id: `permission_id_${data[i].id}`,
                                                    "vs-value": data[i].id,
                                                    checked: data[i].checked,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.selectPermission(
                                                        $event,
                                                        func.id,
                                                        data[i].id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "vs-td",
                                              { attrs: { data: data[i].id } },
                                              [
                                                _c("label", [
                                                  _vm._v(_vm._s(data[i].id)),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "vs-td",
                                              { attrs: { data: data[i].name } },
                                              [
                                                _c("label", [
                                                  _vm._v(_vm._s(data[i].name)),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "vs-td",
                                              {
                                                attrs: {
                                                  data: data[i].display_name,
                                                },
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(data[i].display_name)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "vs-td",
                                              {
                                                attrs: {
                                                  data: data[i].description,
                                                },
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(data[i].description)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      })
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "template",
                                { slot: "thead" },
                                [
                                  _c(
                                    "vs-th",
                                    { attrs: { width: "3%" } },
                                    [
                                      _c("vs-checkbox", {
                                        attrs: { id: `func_id_${func.id}` },
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectPermissionsAll(
                                              $event,
                                              func.id,
                                              func.permissions
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vs-th", { attrs: { width: "5%" } }, [
                                    _vm._v(_vm._s(_vm.$t("id"))),
                                  ]),
                                  _c("vs-th", { attrs: { width: "25%" } }, [
                                    _vm._v(_vm._s(_vm.$t("name"))),
                                  ]),
                                  _c("vs-th", { attrs: { width: "20%" } }, [
                                    _vm._v(_vm._s(_vm.$t("permissions_name"))),
                                  ]),
                                  _c("vs-th", { attrs: { width: "50%" } }, [
                                    _vm._v(_vm._s(_vm.$t("description"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("div", { staticClass: "vx-row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right",
                            attrs: { color: "primary", type: "border" },
                            on: { click: _vm.cancel },
                          },
                          [_vm._v(_vm._s(_vm.$t("back")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.id !== null
            ? _c("vs-tab", { attrs: { label: _vm.$t("permissoes") } }, [
                _c(
                  "div",
                  {
                    staticClass: "vx-row mb-6",
                    staticStyle: { "padding-top": "30px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { icon: "add" },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("adicionar")))]
                        ),
                        _c("vs-divider"),
                        _c("Grid", {
                          key: _vm.gridKey,
                          ref: "grid",
                          attrs: {
                            service: _vm.permissionService,
                            route_name: "permissions",
                            route_grid_path: _vm.permissionsRolePath,
                            route_delete_path: _vm.permissionsRolePath,
                            hide_edit: "true",
                            multipleSelect: true,
                            order_column: "name",
                            delegate: {
                              destroy: () => _vm.destroyPermissionRole,
                            },
                          },
                          on: { changedSelection: _vm.changedSelection },
                        }),
                        _c("div", { staticClass: "vx-row mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _vm.enableActionDeletePermissions
                                ? _c(
                                    "vs-button",
                                    {
                                      staticClass: "float-right",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: _vm.confirmDeletePermissions,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("action.delete")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("permissoes"), active: _vm.showModal },
          on: {
            "update:active": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [
          _vm.showModal
            ? _c("Grid", {
                attrs: {
                  multipleSelect: true,
                  service: _vm.permissionService,
                  selectAllCallback: _vm.selectAll,
                  route_name: "permissions",
                  hide_actions: "true",
                  order_column: "name",
                },
                on: { changedSelection: _vm.changedSelection },
              })
            : _vm._e(),
          _c("vs-button", { on: { click: _vm.addPermissions } }, [
            _vm._v(" " + _vm._s(_vm.$t("adicionar")) + " "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }